import { render, staticRenderFns } from "./Center.vue?vue&type=template&id=50c52ee2&scoped=true&"
import script from "./Center.vue?vue&type=script&lang=js&"
export * from "./Center.vue?vue&type=script&lang=js&"
import style0 from "./Center.vue?vue&type=style&index=0&id=50c52ee2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50c52ee2",
  null
  
)

export default component.exports