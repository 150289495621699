<template>
  <div class="container"></div>
</template>

<script>
export default {
  components: {}
}
</script>

<style scoped lang="scss">
.container {
  @include flex(column);
  position: relative;
}
</style>
