<template>
  <div class="container">
    <l1></l1>
    <l2></l2>
  </div>
</template>

<script>
import l1 from './components/l1'
import l2 from './components/l2'

export default {
  components: {
    l1,
    l2,
  }
}
</script>

<style scoped lang="scss">
.container {
  @include flex(column);
  justify-content: space-between;
  align-content: space-between;
}
</style>
