<template>
  <ek-panel title="建设五大层级和两大保障">
    <div class="box">
      <el-image
        style="width: 100%; height: 100%"
        :preview-src-list="list"
        :lazy="true"
        :src="url"
        fit="fill"
      />
    </div>
  </ek-panel>
</template>

<script>
export default {
  data() {
    return {
      url: '/images/components/ov/dc/l2.png',
      list: ['/images/components/ov/dc/l2-p.png']
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: 473px - 80px;
  padding: 50px 26px 30px;
  @include flex(row);
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
